import { useState } from 'react';
// import ReCAPTCHA from 'react-google-recaptcha';
// import { createRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { useLoginMutation, useLoginWithoutVerificationMutation } from '../../api/authApi';
import Spinner from '../../components/Spinner';
import consts from '../../consts/consts';

const schema = yup
  .object({
    user_email: yup.string().email('Email invalid').required('Camp obligatoriu!'),
    user_password: yup.string().required('Camp obligatoriu')
  })
  .required();

const LoginPage = () => {
  // const recaptchaRef = createRef();
  const navigate = useNavigate();
  const [loginUser] = useLoginMutation();
  const [loginUserWithoutVerification] = useLoginWithoutVerificationMutation();
  const [, setCookie] = useCookies([consts.COOKIE_KEY_JWT]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(data) {
    setIsLoading(true);

    // const token = await recaptchaRef.current.executeAsync();

    // if (!token) {
    //   return;
    // }

    try {
      const { success = false, jwt = '', message = '' } = await loginUserWithoutVerification(data).unwrap();

      if (success) {
        let d = new Date();
        d.setTime(d.getTime() + 6 * 60 * 60 * 1000);
        setCookie(consts.COOKIE_KEY_JWT, jwt, { path: '/', expires: d });
        // return navigate(`/portal`);
      } else {
        // window.grecaptcha.reset();
        toast.error(message);
        setIsLoading(false);
      }

      // const {
      //   success = false,
      //   message = '',
      //   jwt = '',
      //   user = null,
      //   isActivation = false
      // } = await loginUser(data).unwrap();

      // if (success) {
      //   if (isActivation) {
      //     let d = new Date();
      //     d.setTime(d.getTime() + 60 * 60 * 1000);
      //     setCookie(consts.COOKIE_KEY_JWT, jwt, { path: '/', expires: d, sameSite: 'strict', secure: false });
      //     navigate(`/portal`);
      //     toast.success(message);
      //   } else {
      //     return navigate(`/verification/${user.user_id}`);
      //   }
      // } else {
      //   window.grecaptcha.reset();
      //   toast.error(message);
      //   setIsLoading(false);
      // }
    } catch (error) {
      // window.grecaptcha.reset();
      console.error(error);
    }
  }

  return (
    <div className='flex flex-col items-center justify-center h-full w-full minHeightCalc'>
      <form className='form md:w-[25rem] gap-4' onSubmit={handleSubmit(onSubmit)}>
        <h1 className='subTitle'>Conectare</h1>
        <div className='formControl'>
          <label className='formLabel'>Email:</label>
          <input
            type='text'
            className={`formInput ${errors?.user_email && 'border-[0.1px] border-primary-500'}`}
            {...register('user_email')}
          />
          <p className='inputErrorMessage'>{errors?.user_email?.message}</p>
        </div>
        <div className='formControl'>
          <div className='flex items-center justify-between w-full'>
            <label className='formLabel'>Parola:</label>
            <Link to='/reset' className='text-sm link'>
              Ai uitat parola?
            </Link>
          </div>
          <input
            type='password'
            className={`formInput ${errors?.user_password && 'border-[0.1px] border-primary-500'}`}
            {...register('user_password')}
          />
          <p className='inputErrorMessage'>{errors?.user_password?.message}</p>
        </div>
        <button type='submit' className='btn btn-dark self-start w-full' disabled={isLoading}>
          {isLoading ? <Spinner /> :  'Conectare'}
        </button>
        <p className='text-sm'>
          Nu ai un cont creat? Creeaza unul de{' '}
          <Link to='/register' className='font-bold link'>
            aici
          </Link>
          !
        </p>
      </form>
      {/* <ReCAPTCHA
        ref={recaptchaRef}
        size='invisible'
        className='invisible opacity-0 pointer-events-none'
        sitekey={window.RECAPTCHA_SITE_KEY}
      /> */}
    </div>
  );
};

export default LoginPage;
