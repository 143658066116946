import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function useCurrentDepartment(id = null) {
  const [currentDepartment, setCurrentDepartment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  let departmentId = useParams('department_id').departmentId;

  if (id) {
    departmentId = id;
  }

  useEffect(() => {
    let isFetched = false;

    async function fetchDepartment() {
      try {
        setIsLoading(true);
        const res = await fetch(`${window.API_URL}/departments.php?department_id=${departmentId}`);
        const department = await res.json();

        if (!isFetched) {
          setCurrentDepartment(department);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchDepartment();

    return () => (isFetched = true);
  }, [departmentId]);

  return { currentDepartment, isLoading, error };
}

export default useCurrentDepartment;
