import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: window.API_URL,
    prepareHeaders: (headers, { getState }) => {
      return headers;
    }
  }),
  tags: ['Auth'],
  endpoints: (builder) => ({
    activateUser: builder.mutation({
      query: (data) => ({
        url: '/auth.php',
        method: 'POST',
        body: JSON.stringify(data)
      }),
      invalidatesTags: ['Tags']
    }),
    login: builder.mutation({
      query: (data) => ({
        url: '/auth.php',
        method: 'POST',
        body: JSON.stringify(data)
      }),
      invalidatesTags: ['Tags']
    }),
    loginWithoutVerification: builder.mutation({
      query: (data) => ({
        url: '/auth_2.php',
        method: 'POST',
        body: JSON.stringify(data)
      }),
      invalidatesTags: ['Tags']
    }),
    getConnectedUser: builder.query({
      query: () => '/auth.php',
      providesTags: ['Auth']
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/auth.php?logout=1',
        method: 'GET'
      }),
      invalidatesTags: ['Auth']
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: '/auth.php',
        method: 'PUT',
        body: JSON.stringify(data)
      }),
      invalidatesTags: ['Auth']
    })
  })
});

export const { useLoginMutation, useLoginWithoutVerificationMutation, useLogoutMutation, useGetConnectedUserQuery, useResetPasswordMutation } = authApi;

export default authApi;
