import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useResetPasswordMutation } from '../../api/authApi';
import SuccessMessage from '../../components/SuccessMessage';
import Spinner from '../../components/Spinner';

const schema = yup
  .object({
    user_cui_cnp: yup.number().typeError('Campul trebuie sa contina doar cifre!').required('Camp obligatoriu!'),
    user_email: yup.string().email('Email invalid').required('Camp obligatoriu')
  })
  .required();

const successTitle = 'Parola dumneavoastră a fost resetată, accesează emailul pentru a vizualiza noua parolă.';
const successSubtitle =
  'În cazul în care întâmpini dificultăți la autentificare, te rugăm să ne contactezi pentru asistență suplimentară.';

const PasswordResetPage = () => {
  const navigate = useNavigate();
  const [resetPassword] = useResetPasswordMutation();
  const [isResetPassSuccess, setIsResetPassSuccess] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(data) {
    setIsSubmitLoading(true)
    
    try {
      const { success = false, message = '' } = await resetPassword(data).unwrap();

      if (success) {
        setIsResetPassSuccess(true);
        setTimeout(() => {
          return navigate('/');
        }, 3000);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error(error);
    }

    setIsSubmitLoading(false)
  }

  if (!isResetPassSuccess) {
    return (
      <div className='flex flex-col items-center justify-center h-full w-full minHeightCalc'>
        <form className='form md:w-[25rem] gap-6' onSubmit={handleSubmit(onSubmit)}>
          <h1 className='subTitle'>Resetare parola</h1>
          <div className='formControl'>
            <label className='formLabel'>CUI/CNP:</label>
            <input
              type='text'
              className={`formInput ${errors?.user_cui_cnp && 'border-[0.1px] border-primary-500'}`}
              {...register('user_cui_cnp')}
            />
            <p className='inputErrorMessage'>{errors?.user_cui_cnp?.message}</p>
          </div>
          <div className='formControl'>
            <label className='formLabel'>Email:</label>
            <input
              type='text'
              className={`formInput ${errors?.user_email && 'border-[0.1px] border-primary-500'}`}
              {...register('user_email')}
            />
            <p className='inputErrorMessage'>{errors?.user_email?.message}</p>
          </div>
          <button type='submit' className='btn btn-dark self-start w-full '>
            Trimite
          </button>
          {isSubmitLoading && <Spinner />}
        </form>
      </div>
    );
  } else {
    return <SuccessMessage title={successTitle} subtitle={successSubtitle} />;
  }
};

export default PasswordResetPage;
