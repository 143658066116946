import { Navigate, Outlet } from 'react-router-dom';
import { useGetConnectedUserQuery } from '../../api/authApi';
import { useCookies } from 'react-cookie';
import consts from '../../consts/consts';

const PrivateRoute = () => {
  const [cookies] = useCookies([consts.COOKIE_KEY_JWT]);

  const { data: user, isLoading } = useGetConnectedUserQuery(cookies[consts.COOKIE_KEY_JWT]);

  if (!isLoading && user) {
    return <Navigate to='/portal' />;
  }

  return <>{!isLoading && <Outlet context={{ connectedUser: user, isUserLoading: isLoading }} />}</>;
};

export default PrivateRoute;
