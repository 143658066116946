const Spinner = ({ size = '25', color = '#eb322b' }) => {
  return (
    size && (
      <span
        className='loader'
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderWidth: `${size[0]}px`,
          border: `solid ${color}`,
          borderBottomColor: 'transparent'
        }}
      ></span>
    )
  );
};

export default Spinner;
