import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLoginMutation } from '../../api/authApi';
import { useGetUserQuery } from '../../api/usersApi';
import { useNavigate, useParams } from 'react-router-dom';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import consts from '../../consts/consts';
import Spinner from '../../components/Spinner';

const schema = yup
  .object({
    verification_code: yup.string().required('Camp obligatoriu')
  })
  .required();

const VerificationPage = () => {
  const navigate = useNavigate();
  const [loginUser] = useLoginMutation();
  const [, setCookie] = useCookies([consts.COOKIE_KEY_JWT]);
  const params = useParams();
  const { data: user, isLoading } = useGetUserQuery(params?.userId);
  const [showSpinner, setShowSpinner] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) });

  if (!params?.userId || (!isLoading && !user)) {
    // return navigate('/');
  }

  async function onSubmit(data) {
    setShowSpinner(true);

    try {
      data = { ...data, user_id: params?.userId, ...user };

      const { success = false, message = '', jwt = '' } = await loginUser(data).unwrap();

      if (success) {
        let d = new Date();
        d.setTime(d.getTime() + 6 * 60 * 60 * 1000);
        setCookie(consts.COOKIE_KEY_JWT, jwt, { path: '/', expires: d });
        // return navigate(`/dashboard`);
        return navigate(`/portal`);
      } else {
        setShowSpinner(false);
        toast.error(message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className='flex flex-col items-center justify-center h-full w-full minHeightCalc'>
      {!isLoading && (
        <form className='form md:w-[30rem] gap-4' onSubmit={handleSubmit(onSubmit)}>
          <h1 className='subTitle'>Cod verificare</h1>
          <div className='formControl'>
            <label className='formLabel'>Cod:</label>
            <input
              type='text'
              className={`formInput ${errors?.verification_code && 'border-[0.1px] border-primary-500'}`}
              {...register('verification_code')}
            />
            <p className='inputErrorMessage'>{errors?.verification_code?.message}</p>
          </div>
          <button type='submit' className='btn btn-dark self-start w-full '>
            Conectare
          </button>
          {showSpinner && <Spinner />}
          <div className='alert alertInfo px-3 text-sm w-full'>
            <BsFillInfoCircleFill />
            <span>Codul a fost trimis catre adresa dvs. de email!</span>
          </div>
        </form>
      )}
    </div>
  );
};

export default VerificationPage;
