import DebitItem from './DebitItem';

const DebitsList = ({ roles }) => {
  if (roles !== null && typeof roles === 'object' && !Array.isArray(roles)) {
    roles = Object.values(roles);
  }

  return (
    <div className='flex flex-col gap-2'>
      {roles.map((debits, idx) => (
        <div key={idx} className='flex flex-col gap-2 justify-center'>
          <div className='rounded bg-secondary-100 p-1 px-2 text-secondary-500 font-bold border-[1px] border-secondary-200'>
            ROL {debits[0]?.debiton_rolnr}
          </div>
          <DebitItem debits={debits} />
        </div>
      ))}
    </div>
  );
};

export default DebitsList;
