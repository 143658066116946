const Footer = ({ logoUrl, hallName, hallAddress, hallPhone, hallEmail, hallWebsite }) => {
  return (
    <footer className={`relative bottom-0 left-0 right-0 text-white flex flex-col`}>
      <div className='bg-secondary-500 flex items-center justify-center pt-4'>
        {/* <img src={logoUrl} alt='stema primarie' width='70' height='70' /> */}
        <img src={logoUrl} alt='stema primarie' width='100' height='100' className='rounded-xl' />
      </div>
      <div className='flex flex-col items-center justify-center py-4 bg-secondary-500'>
        <p className='font-bold text-[0.8rem] uppercase leading-[1.2]'>{hallName}</p>
        <p className='text-[0.8rem] uppercase leading-[1.2]'>Portal online</p>
        <p className='text-[0.8rem] leading-[1.2]'>{hallAddress}</p>
        <p className='text-[0.8rem] leading-[1.2]'>
          {hallPhone}, {hallEmail}
        </p>
        <p className='text-[0.8rem] leading-[1.2]'>{hallWebsite}</p>
        <p className='text-[0.9rem] font-bold uppercase text-primary-500 mt-4 tracking-widest'>Infoghid</p>
      </div>
    </footer>
  );
};

export default Footer;
