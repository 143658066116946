import React from 'react';
import { FaFilePdf } from "react-icons/fa";

const AboutPage = () => {
  return (
    <div className='flex flex-col gap-4'>
      <h1 className='title'>Despre</h1>
      <h3 className='subTitle'>
        Versiune <span className='text-primary-500'>0.9</span>
      </h3>
      <h1 className='title mt-16'>Anexe</h1>
      <a className='text-primary-500 underline flex gap-1 items-center' target='_blank' rel="noreferrer" href="https://dgaspccs.appifg.ro/docs/guide1.pdf">
        <FaFilePdf />
        Manual Utilizare Registratura Online - Operatori Departamente
      </a>
    </div>
  );
};

export default AboutPage;
