import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentDepartment: null
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setCurrentDepartment(state, action) {
      state.currentDepartment = action.payload.department;
    }
  }
});

export const { setCurrentDepartment } = globalSlice.actions;

export default globalSlice.reducer;
