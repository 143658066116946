import MaterialReactTable, { MRT_ShowHideColumnsButton, MRT_FullScreenToggleButton } from 'material-react-table';
import { MRT_Localization_RO } from 'material-react-table/locales/ro';
import { useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useGetWebincarolQuery } from '../../api/webincarolApi';
import { useGetUserQuery, useGetUsersQuery } from '../../api/usersApi';
import { getUsername } from '../../utils/fn';
import { webincarolStatusRev } from '../../consts/webincarol';
import { BsFillFilePdfFill } from 'react-icons/bs';
import useCurrentDepartment from '../../hooks/useCurrentDepartment';

const PaymentHistoryPage = () => {
  const { currentDepartment, isLoading: isCurrentDepartmentLoading } = useCurrentDepartment();

  function getAddress(data) {
    if (!data) {
      return '';
    }

    let address = [data.omulon_denloc, data.omulon_d_str, data.omulon_d_nr, data.omulon_d_bloc, data.omulon_d_jd];
    address = address.filter(Boolean);

    if (address.length) {
      address = address.filter((x) => x.trim());

      return address.join(', ');
    }

    return '';
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'debiton_dendeb',
        header: 'Debit',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>,
        size: 200
      },
      {
        accessorKey: 'webincarol_numpre',
        accessorFn: (row) => `${row.webincarol_numpre} (${row.webincarol_cuicnp})`,
        header: 'Platitor',
        size: 250
      },
      {
        accessorKey: 'address',
        accessorFn: (row) => getAddress(row),
        header: 'Adresa'
      },
      {
        accessorKey: 'debiton_andeb',
        header: 'An',
        size: 70
      },
      {
        accessorKey: 'webincarol_suminca',
        header: 'Val. plata',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>,
        size: 120
      },
      {
        accessorKey: 'webincarol_status',
        header: 'Status',
        Cell: ({ cell }) => (
          <strong className={cell.getValue() != 2 ? 'text-danger-500' : 'text-green-500'}>
            {webincarolStatusRev[cell.getValue()]}
          </strong>
        ),
        size: 100
      },
      {
        accessorKey: 'webincarol_dataweb',
        header: 'Data plata'
      }
    ],
    []
  );

  const [datesInterval, setDatesInterval] = useState({
    fromDate: `${new Date().getFullYear()}-01-01`,
    toDate: `${new Date().getFullYear()}-12-31`
  });

  const { data: users, isLoading: isUsersLoading } = useGetUsersQuery();

  const [selectedUserId, setSelectedUserId] = useState(0);

  const { data: selectedUser } = useGetUserQuery(selectedUserId);

  const { connectedUser, isUserLoading } = useOutletContext();

  const { data: webincarol, isLoading: isWebincarolLoading } = useGetWebincarolQuery(
    !Boolean(+connectedUser?.user_is_super_admin) && !Boolean(+connectedUser?.user_is_admin)
      ? connectedUser?.user_cui_cnp
      : null
  );

  const webincarolFiltered = useMemo(
    () =>
      webincarol?.filter((x) => {
        const fromDate = new Date(datesInterval.fromDate);
        const toDate = new Date(datesInterval.toDate);
        const paymentDate = new Date(x.webincarol_dataweb);

        if (selectedUserId > 0) {
          return x.webincarol_cuicnp == selectedUser?.user_cui_cnp && paymentDate >= fromDate && paymentDate <= toDate;
        }

        return paymentDate >= fromDate && paymentDate <= toDate;
      }),
    [webincarol, selectedUser, selectedUserId, datesInterval]
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!isUserLoading && !Boolean(+connectedUser?.user_is_admin) && !Boolean(+connectedUser?.user_is_activated_2)) {
      return navigate('/portal');
    }
  }, [isUserLoading, connectedUser, navigate]);

  async function generateReport(e) {
    e.preventDefault();
    try {
      const res = await fetch(
        `${window.API_URL}/taxes/payments_report.php?fromDate=${datesInterval.fromDate}&toDate=${datesInterval.toDate}&userId=${selectedUserId}`,
        {
          credentials: 'include'
        }
      );
      const { filePath = '', fileName } = await res.json();
      filePath.length && window.open(filePath, '_blank');
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className='flex flex-col gap-4'>
      <h1 className='title'>{currentDepartment?.department_name} / Istoric plati</h1>
      <div className='w-full'>
        <MaterialReactTable
          columns={columns}
          data={webincarolFiltered || []}
          getRowId={(row) => row.user_id}
          state={{ isWebincarolLoading }}
          enableColumnResizing
          localization={MRT_Localization_RO}
          initialState={{
            showGlobalFilter: true,
            density: 'compact'
          }}
          enablePinning
          muiSearchTextFieldProps={{
            sx: {
              padding: '0',
              display: 'flex',
              alignSelf: 'flex-end',
              '& > *': {
                height: '37px'
              }
            }
            // variant: 'outlined'
          }}
          defaultColumn={{
            maxSize: 400,
            minSize: 80,
            size: 150 //default size is usually 180
          }}
          enableDensityToggle={false}
          layoutMode='grid'
          positionActionsColumn='last'
          muiTableHeadCellProps={{
            sx: {
              color: 'black',
              fontWeight: 'bold'
            }
          }}
          muiTableContainerProps={{
            sx: {
              maxHeight: '500px'
            }
          }}
          muiTablePaperProps={{
            sx: {
              borderRadius: '0',
              border: '0.1px solid #e0e0e0',
              boxShadow: 'none'
            }
          }}
          muiTableProps={{
            sx: {
              boxShadow: 'none'
            }
          }}
          muiTableHeadRowProps={{
            sx: {
              boxShadow: 'none'
            }
          }}
          muiTableBodyCellProps={{
            sx: {
              whiteSpace: 'pre-line'
            }
          }}
          muiTopToolbarProps={{
            sx: {
              alignItems: 'flex-end',
              '& > *': {
                display: 'flex',
                alignItems: 'flex-end'
              }
            }
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <div className='flex items-end justify-start gap-1 flex-wrap'>
              <button className='btn btn-dark gap-1 whitespace-nowrap px-4' onClick={generateReport}>
                <BsFillFilePdfFill />
                Generare raport
              </button>
              <div className='basis-0'>
                <label htmlFor='fromDate' className='text-sm'>
                  De la:
                </label>
                <input
                  type='date'
                  className='formInput'
                  name='fromDate'
                  id='fromDate'
                  value={datesInterval.fromDate}
                  onChange={(e) =>
                    setDatesInterval({
                      ...datesInterval,
                      [e.target.name]: e.target.value
                    })
                  }
                />
              </div>
              <div className='basis-0'>
                <label htmlFor='toDate' className='text-sm'>
                  Pana la:
                </label>
                <input
                  type='date'
                  className='formInput'
                  id='toDate'
                  name='toDate'
                  value={datesInterval.toDate}
                  onChange={(e) =>
                    setDatesInterval({
                      ...datesInterval,
                      [e.target.name]: e.target.value
                    })
                  }
                />
              </div>
              {Boolean(+connectedUser.user_is_admin) || Boolean(+connectedUser.user_is_super_admin) ? (
                <div className='max-w-[200px]'>
                  <label htmlFor='users' className='text-sm'>
                    Utilizator:
                  </label>
                  <select
                    onChange={(e) => setSelectedUserId(e.target.value)}
                    id='users'
                    className='formInput py-[0.409rem]'
                    defaultValue={0}
                  >
                    <option value='0'>TOTI UTLIZATORII</option>
                    {!isUsersLoading &&
                      users?.map((user) => (
                        <option value={user.user_id} key={user.user_id}>
                          {getUsername(user)}
                        </option>
                      ))}
                  </select>
                </div>
              ) : null}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default PaymentHistoryPage;
