import * as yup from 'yup';

const stringSchema = yup.string('Campul trebuie sa contina doar litere!').required('Camp obligatoriu!');
const numberSchema = yup.number().typeError('Campul trebuie sa contina doar cifre!').required('Camp obligatoriu!');

export const validationSchema = yup.object().shape({
  settings_hall_name: stringSchema,
  settings_hall_cif: numberSchema,
  settings_hall_email: stringSchema.email('Email invalid'),
  settings_hall_phone: stringSchema,
  settings_hall_website: yup.string('Campul trebuie sa contina doar litere!'),
  settings_hall_address: stringSchema,
  settings_admin_email_address: stringSchema.email('Email invalid'),
  settings_admin_email_smtp: stringSchema,
  settings_admin_email_port: numberSchema,
  settings_admin_email_password: yup.string().required('Camp obligatoriu!'),
  // settings_noreply_email_address: stringSchema.email('Email invalid'),
  // settings_noreply_email_smtp: stringSchema,
  // settings_noreply_email_port: numberSchema,
  // settings_noreply_email_password: yup.string().required('Camp obligatoriu!'),
  settings_upload_data_path: yup.string('Campul trebuie sa contina doar litere!')
});
