import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

const logsApi = createApi({
  reducerPath: 'logsApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: window.API_URL
  }),
  tags: ['Logs'],
  endpoints: (builder) => ({
    getLogs: builder.query({
      query: (userId = 0) => `/logs.php?userId=${userId}`,
      providesTags: ['Logs']
    })
  })
});

export const { useGetLogsQuery } = logsApi;

export default logsApi;
