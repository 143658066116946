import FileBox from './FileBox';

const FilesList = ({ files, fileBoxClick_callback, fileBoxClick_lightbox }) => {
  return (
    <div className='flex flex-wrap gap-4'>
      {!!files?.length &&
        files?.map((file, idx) => (
          <FileBox
            file={file}
            fileBoxClick_callback={fileBoxClick_callback}
            fileBoxClick_lightbox={fileBoxClick_lightbox}
            key={idx}
          />
        ))}
    </div>
  );
};

export default FilesList;
