import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RO } from 'material-react-table/locales/ro';
import { useEffect, useMemo, useState } from 'react';
import { useGetLogsQuery } from '../../api/logsApi';
import { formatDate } from '../../utils/fn';
import { useGetUsersQuery } from '../../api/usersApi';
import { getUsername } from '../../utils/fn';
import { useOutletContext } from 'react-router-dom';

const LogsPage = () => {
  const { data: logs, isLoading: isLogsLoading, refetch: refetchLogs } = useGetLogsQuery();
  const { data: users, isLoading: isUsersLoading } = useGetUsersQuery();
  const [selectedUserId, setSelectedUserId] = useState(0);
  const { connectedUser, isUserLoading } = useOutletContext();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'log_operation',
        header: 'Operatie',
        size: 100
      },
      {
        accessorFn: (row) => getUsername(row),
        header: 'User',
        size: 100
      },
      {
        accessorKey: 'log_description',
        header: 'Descriere',
        size: 300
      },
      {
        accessorKey: 'log_created_at',
        accessorFn: (row) => formatDate(row.log_created_at, true),
        header: 'Data',
        size: 80
      }
    ],
    []
  );

  let filteredLogs = useMemo(
    () => (selectedUserId == 0 ? logs : logs?.filter(({ log_user_id }) => log_user_id == selectedUserId) || []),
    [selectedUserId, logs]
  );

  useEffect(() => {
    refetchLogs();
  }, [refetchLogs]);

  return (
    <div className='flex flex-col gap-4'>
      <h1 className='title'>Jurnal</h1>
      <div className='w-full'>
        <MaterialReactTable
          columns={columns}
          data={filteredLogs ?? []}
          getRowId={(row) => row.log_id}
          state={{ isLogsLoading }}
          enableStickyHeader
          enableStickyFooter
          enableColumnResizing
          localization={MRT_Localization_RO}
          initialState={{
            showGlobalFilter: true,
            density: 'compact',
            columnPinning: { right: ['actions'] }
          }}
          renderTopToolbarCustomActions={({ table }) =>
            (!!+connectedUser?.user_is_admin || !!+connectedUser?.user_is_super_admin) && (
              <select
                onChange={(e) => setSelectedUserId(e.target.value)}
                className='formInput w-[50%] md:w-[200px]'
                defaultValue={0}
              >
                <option value='0'>TOTI UTLIZATORII</option>
                {!isUsersLoading &&
                  users?.map((user) => (
                    <option value={user.user_id} key={user.user_id}>
                      {getUsername(user)}
                    </option>
                  ))}
              </select>
            )
          }
          enablePinning
          // defaultColumn={{
          //   maxSize: 400,
          //   minSize: 80,
          //   size: 100 //default size is usually 180
          // }}
          enableDensityToggle={false}
          layoutMode='grid'
          // enableRowActions
          // displayColumnDefOptions={{
          //   'mrt-row-actions': {
          //     header: '', //change header text
          //     minSize: 142,
          //     maxSize: 300
          //   }
          // }}
          positionActionsColumn='last'
          muiTableBodyCellProps={{
            sx: {
              whiteSpace: 'pre-line'
            }
          }}
          muiTableHeadCellProps={{
            sx: {
              // background: "#475569",
              color: 'black',
              fontWeight: 'bold'
            }
          }}
          muiTableContainerProps={{
            sx: {
              maxHeight: '500px'
            }
          }}
          muiTablePaperProps={{
            sx: {
              borderRadius: '0',
              border: '0.1px solid #e0e0e0',
              boxShadow: 'none'
            }
          }}
          muiTableProps={{
            sx: {
              boxShadow: 'none'
            }
          }}
          muiTableHeadRowProps={{
            sx: {
              boxShadow: 'none'
            }
          }}
        />
      </div>
    </div>
  );
};

export default LogsPage;
