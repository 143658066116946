import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const taxesApi = createApi({
  reducerPath: 'taxesApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: window.API_URL,
    prepareHeaders: (headers, { getState }) => {
      return headers;
    }
  }),
  tags: ['Taxes'],
  endpoints: (builder) => ({
    getAllUserTaxes: builder.query({
      query: (userCuiCnp) => `/taxes/taxes.php?userCuiCnp=${userCuiCnp}`,
      providesTags: ['Taxes']
    })
  })
});

export const { useGetAllUserTaxesQuery } = taxesApi;

export default taxesApi;
