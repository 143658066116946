import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useLoginMutation } from '../../api/authApi';
import Spinner from '../../components/Spinner';
import SuccessMessage from '../../components/SuccessMessage';

const succesTitle = 'Contul tau a fost activat cu succes, iar o parola generata automat a fost trimisa pe adresa ta de email.';
const successSubtitle = 'Iti recomandam sa iti schimbi parola imediat ce te autentifici pentru prima data in sectiunea de profil.';

const ActivationPage = () => {
  const params = useParams();
  const [activateUser] = useLoginMutation();

  const [isActivated, setIsActivated] = useState(false);

  const { activationCode } = params;

  const setUserActivated = useCallback(async () => {
    try {
      const requestBody = {
        activationCode: activationCode
      };

      const { success, isActivation } = await activateUser(requestBody).unwrap();

      if (success) {
        setIsActivated(isActivation);
      }
    } catch (error) {
      console.error(error);
    }
  }, [activateUser, activationCode]);

  useEffect(() => {
    setUserActivated();
  }, [setUserActivated]);

  if (!isActivated) {
    return (
      <div className='flex items-center justify-center minHeightCalc'>
        <Spinner size='60' />
      </div>
    );
  }

  return <SuccessMessage title={succesTitle} subtitle={successSubtitle} />;
};

export default ActivationPage;
