import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import usersApi from './api/usersApi';
import authApi from './api/authApi';
import settingsApi from './api/settingsApi';
import statsApi from './api/statsApi';
import eventsLogsApi from './api/eventsLogsApi';
import logsApi from './api/logsApi';

import webincarolApi from './api/webincarolApi';
import taxesApi from './api/taxesApi';

import departmentsApi from './api/departmentsApi';

import globalSlice from './slices/globalSlice';

import typesApi from './api/typesApi';

import ticketsApi from './api/ticketsApi';

import categoriesApi from './api/categoriesApi';

import infosApi from './api/infosApi';

const store = configureStore({
  reducer: {
    global: globalSlice,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [ticketsApi.reducerPath]: ticketsApi.reducer,
    [typesApi.reducerPath]: typesApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [taxesApi.reducerPath]: taxesApi.reducer,
    [statsApi.reducerPath]: statsApi.reducer,
    [logsApi.reducerPath]: logsApi.reducer,
    [webincarolApi.reducerPath]: webincarolApi.reducer,
    [eventsLogsApi.reducerPath]: eventsLogsApi.reducer,
    [departmentsApi.reducerPath]: departmentsApi.reducer,
    [infosApi.reducerPath]: infosApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      categoriesApi.middleware,
      ticketsApi.middleware,
      typesApi.middleware,
      usersApi.middleware,
      authApi.middleware,
      settingsApi.middleware,
      taxesApi.middleware,
      statsApi.middleware,
      logsApi.middleware,
      webincarolApi.middleware,
      eventsLogsApi.middleware,
      departmentsApi.middleware,
      infosApi.middleware
    )
});

setupListeners(store.dispatch);

export default store;
