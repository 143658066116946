import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: window.API_URL,
    credentials: 'include'
  }),
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    verifyUser: builder.mutation({
      query: (body) => ({
        url: `/verification.php`,
        method: 'POST',
        body: JSON.stringify(body)
      }),
      invalidatesTags: ['Users']
    }),
    discardUserVerification: builder.mutation({
      query: (body) => ({
        url: '/verification.php',
        method: 'PATCH',
        body: JSON.stringify(body)
      }),
      invalidatesTags: ['Users']
    }),
    getNormalUsers: builder.query({
      query: () => '/users.php?withoutAdmins=1',
      providesTags: ['Users']
    }),
    getAdminUsers: builder.query({
      query: () => '/users.php?admins=1',
      providesTags: ['Users']
    }),
    getUsers: builder.query({
      query: () => '/users.php',
      providesTags: ['Users']
    }),
    getUser: builder.query({
      query: (id) => `/users.php?id=${id}`,
      providesTags: ['Users']
    }),
    addUser: builder.mutation({
      query: (data) => ({
        url: '/users.php',
        method: 'POST',
        body: JSON.stringify(data)
      }),
      invalidatesTags: ['Users']
    }),
    updateUser: builder.mutation({
      query: ({ data, id }) => ({
        url: `/users.php?id=${id}`,
        method: 'PUT',
        body: JSON.stringify(data)
      }),
      invalidatesTags: ['Users']
    }),
    activateUser2: builder.mutation({
      query: (id) => ({
        url: `/users.php`,
        method: 'PATCH',
        body: JSON.stringify({ id })
      }),
      invalidatesTags: ['Users']
    }),
    removeUser: builder.mutation({
      query: (id) => ({
        url: `/users.php?id=${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Users']
    })
  })
});

export const {
  useGetNormalUsersQuery,
  useGetUserQuery,
  useGetAdminUsersQuery,
  useGetUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useRemoveUserMutation,
  useActivateUser2Mutation,
  useVerifyUserMutation,
  useDiscardUserVerificationMutation
} = usersApi;

export default usersApi;
