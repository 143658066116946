import {
  useGetDepartmentsQuery,
  useAddDepartmentMutation,
  useUpdateDepartmentMutation,
  useRemoveDepartmentMutation
} from '../api/departmentsApi';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RO } from 'material-react-table/locales/ro';
import Modal from '@mui/material/Modal';
import { useEffect, useMemo, useState, useRef } from 'react';
import { formatDate } from '../utils/fn';
import { AiFillEdit, AiOutlineClose } from 'react-icons/ai';
import { FaCog, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { departmentTypes } from '../consts/departments';
import usersApi from '../api/usersApi';
import { useDispatch } from 'react-redux';
import Spinner from '../components/Spinner';

const DepartmentsPage = () => {
  const { data: departments, isLoading: isDepartmentsLoading } = useGetDepartmentsQuery(2);

  const [operatedDepartment, setOperatedDepartment] = useState(null);

  const [operatedDepartmentId, setOperatedDepartmentId] = useState(0);

  const [departmentToUpdate, setDepartmentToUpdate] = useState(null);

  const [removeDepartment] = useRemoveDepartmentMutation();

  const [addDepartment] = useAddDepartmentMutation();

  const [updateDepartment] = useUpdateDepartmentMutation();

  const [isDepartmentModalOpen, setIsDepartmentModalOpen] = useState(false);

  const [isDepartmentRemoveModalOpen, setIsDepartmentRemoveModalOpen] = useState(false);

  const departmentFormRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'department_name',
        header: 'Denumire',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>,
        size: 100
      },
      {
        accessorKey: 'department_description',
        header: 'Descriere',
        size: 300
      },
      {
        accessorKey: 'department_created_at',
        accessorFn: (row) => formatDate(new Date(row.department_created_at).toLocaleDateString('en-US'), false),
        header: 'Data',
        size: 80
      }
    ],
    []
  );

  const schema = yup.object().shape({
    department_name: yup.string().required('Camp obligatoriu'),
    department_description: yup.string().required('Camp obligatoriu')
  });

  const {
    register,
    trigger,
    resetField,
    setValue,
    setError,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) });

  function closeDepartmentModal() {
    setDepartmentToUpdate(null);
    resetDepartmentModalFields();
    setIsDepartmentModalOpen(false);
    setOperatedDepartment(null);
  }

  function resetDepartmentModalFields() {
    resetField('department_name');
    resetField('department_description');
  }

  function editDepartment_handler(departament) {
    setDepartmentToUpdate(departament);
    setIsDepartmentModalOpen(true);
    setValue('department_name', departament.department_name);
    setValue('department_description', departament.department_description);
  }

  async function removeDepartment_handler(department) {
    setIsLoading(true);

    try {
      const res = await removeDepartment(department.department_id).unwrap();
      const { success = false, message = '' } = res;
      if (success) {
        message && toast.success(message);
        closeDepartmentRemoveModal();
      } else {
        message && toast.error(message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function addOrUpdateDepartment_handler() {
    try {
      let res;

      const isValid = await trigger();

      if (!isValid) {
        return;
      }

      const formData = Object.fromEntries(new FormData(departmentFormRef.current).entries());

      setIsLoading(true);

      if (departmentToUpdate) {
        res = await updateDepartment({ data: formData, id: departmentToUpdate.department_id }).unwrap();
      } else {
        formData.department_type = departmentTypes['TICKETS'];
        res = await addDepartment(formData).unwrap();
      }

      const { success = false, message = '', id = 0 } = res;

      if (success) {
        message && toast.success(message);
        closeDepartmentModal();
        dispatch(usersApi.util.resetApiState());
      } else {
        message && toast.error(message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function closeDepartmentRemoveModal() {
    setOperatedDepartment(null);
    setIsDepartmentRemoveModalOpen(false);
  }

  return (
    <div className='flex flex-col gap-4'>
      <h1 className='subTitle'>Departamente</h1>
      {/* modal for adding the department */}
      <Modal
        open={isDepartmentModalOpen}
        onClose={(e) => {
          setDepartmentToUpdate(null);
          setIsDepartmentModalOpen(false);
          resetDepartmentModalFields();
        }}
      >
        <div className='modalContainer md:max-w-[600px]'>
          <div className='modalHeader'>
            <h1 className='subTitle text-white'>
              {departmentToUpdate ? 'Modificare department' : 'Adaugare departament'}
            </h1>
            <button
              className='text-gray-100 hover:text-white text-xl'
              onClick={(e) => {
                setDepartmentToUpdate(null);
                setIsDepartmentModalOpen(false);
                resetDepartmentModalFields();
              }}
            >
              <AiOutlineClose />
            </button>
          </div>
          <div className='modalBody'>
            <form ref={departmentFormRef} className='w-full flex flex-col'>
              <div className='formControl'>
                <label className='formLabel'>Denumire:</label>
                <input
                  type='text'
                  className={`formInput ${errors?.department_name && 'border-[0.1px] border-primary-500'}`}
                  {...register('department_name')}
                />
                <p className='inputErrorMessage'>{errors?.department_name?.message}</p>
              </div>
              <div className='formControl'>
                <label className='formLabel'>Descriere:</label>
                <textarea
                  type='text'
                  className={`formInput ${errors?.department_description && 'border-[0.1px] border-primary-500'}`}
                  rows={5}
                  {...register('department_description')}
                />
                <p className='inputErrorMessage'>{errors?.department_description?.message}</p>
              </div>
            </form>
          </div>
          <div className='modalFooter'>
            <button
              onClick={(e) => addOrUpdateDepartment_handler()}
              type='submit'
              className='btn btn-primary self-start w-full xs:max-w-fit'
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : (departmentToUpdate ? 'Modificare' : 'Adaugare')}
            </button>
            <button
              className='btn btn-dark'
              onClick={() => {
                setDepartmentToUpdate(null);
                setIsDepartmentModalOpen(false);
                resetDepartmentModalFields();
              }}
            >
              Anulare
            </button>
          </div>
        </div>
      </Modal>
      {/* modal for removing the department */}
      <Modal open={isDepartmentRemoveModalOpen} onClose={closeDepartmentRemoveModal}>
        <div className='modalContainer md:max-w-[600px]'>
          <div className='modalHeader'>
            <h1 className='subTitle text-white'>Eliminare departament</h1>
            <button className='text-gray-100 hover:text-white text-xl' onClick={closeDepartmentRemoveModal}>
              <AiOutlineClose />
            </button>
          </div>
          <div className='modalBody'>
            <p>
              Departamentul <strong className='uppercase'>{operatedDepartment?.department_name}</strong> va fi eliminat,
              sunteti sigur?
            </p>
          </div>
          <div className='modalFooter'>
            <button className='btn btn-primary' onClick={() => removeDepartment_handler(operatedDepartment)} disabled={isLoading}>
              {isLoading ? <Spinner /> : 'Da'}
            </button>
            <button className='btn btn-dark' onClick={closeDepartmentRemoveModal}>
              Anulare
            </button>
          </div>
        </div>
      </Modal>
      <div className='w-full'>
        <MaterialReactTable
          columns={columns}
          data={departments ?? []}
          getRowId={(row) => row.department_id}
          state={{ isDepartmentsLoading }}
          enableStickyHeader
          enableStickyFooter
          enableColumnResizing
          localization={MRT_Localization_RO}
          initialState={{
            showGlobalFilter: true,
            density: 'compact',
            columnPinning: { right: ['actions'] }
          }}
          // renderTopToolbarCustomActions={({ table }) => null}
          enablePinning
          // defaultColumn={{
          //   maxSize: 400,
          //   minSize: 80,
          //   size: 100 //default size is usually 180
          // }}
          enableDensityToggle={false}
          layoutMode='grid'
          enableRowActions
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: '', //change header text
              minSize: 30,
              maxSize: 300
            }
          }}
          renderRowActions={({ row: { original: departament } }, index) => (
            <div className='flex gap-1'>
              {departament?.department_type?.toLowerCase() != departmentTypes['TAXES'] ? (
                <>
                  <button
                    className='btn btn-outline btn-xm hover:scale-[0.99] p-1'
                    onClick={(e) => {
                      setOperatedDepartmentId(departament.department_id);
                      setOperatedDepartment(departament);
                      setIsDepartmentRemoveModalOpen(true);
                    }}
                  >
                    <FaTrash />
                  </button>
                  <button
                    className='btn btn-outline btn-xm hover:scale-[0.99] p-1'
                    onClick={(e) => {
                      setOperatedDepartmentId(departament.department_id);
                      setOperatedDepartment(departament);
                      editDepartment_handler(departament);
                    }}
                  >
                    <AiFillEdit />
                  </button>
                </>
              ) : (
                ''
              )}
            </div>
          )}
          muiTableBodyRowProps={({ row: { original: departament } }) => ({
            sx: {
              ...(departament.department_id === operatedDepartmentId && { background: '#fbd6d3' })
            },
            hover: false
          })}
          renderTopToolbarCustomActions={({ table }) => (
            <div>
              <button className='btn btn-dark' onClick={(e) => setIsDepartmentModalOpen(true)}>
                Adaugare
              </button>
            </div>
          )}
          positionActionsColumn='last'
          muiTableBodyCellProps={{
            sx: {
              whiteSpace: 'pre-line'
            }
          }}
          muiTableHeadCellProps={{
            sx: {
              // background: "#475569",
              color: 'black',
              fontWeight: 'bold'
            }
          }}
          muiTableContainerProps={{
            sx: {
              maxHeight: '500px'
            }
          }}
          muiTablePaperProps={{
            sx: {
              borderRadius: '0',
              border: '0.1px solid #e0e0e0',
              boxShadow: 'none'
            }
          }}
          muiTableProps={{
            sx: {
              boxShadow: 'none'
            }
          }}
          muiTableHeadRowProps={{
            sx: {
              boxShadow: 'none'
            }
          }}
        />
      </div>
    </div>
  );
};

export default DepartmentsPage;
