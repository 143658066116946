import { Navigate, Outlet, useOutletContext } from 'react-router-dom';

const AdminRoute = () => {
  const { connectedUser: user, isUserLoading } = useOutletContext();

  if (!isUserLoading && !Boolean(+user?.user_is_admin) && !Boolean(+user?.user_is_super_admin)) {
    return <Navigate to='/' />;
  }

  return <>{!isUserLoading && <Outlet context={{ connectedUser: user, isUserLoading }} />}</>;
};

export default AdminRoute;
