import { AiFillFile } from 'react-icons/ai';
import { getFileNameWithoutExtension, isImageFile } from '../utils/fn';
import { useRef } from 'react';
import { FaTrash } from 'react-icons/fa';
import { AiFillEye } from 'react-icons/ai';

const FileBox = ({ file, fileBoxClick_callback, fileBoxClick_lightbox }) => {
  const fileBoxRef = useRef(null);

  async function fileBoxClick_handler(e, file) {
    e.preventDefault();
    fileBoxClick_callback(e, file, fileBoxRef);
  }

  return (
    <>
      {isImageFile(file['web']['fileName']) ? (
        <div
          className='relative shadow-sm rounded border-[0.1px] border-secondary-200 cursor-pointer group flex items-center justify-center'
          ref={fileBoxRef}
        >
          <img
            className='object-cover bg-no-repeat max-w-[200px] h-[100px]'
            src={file['web']['filePath']}
            alt={file['web']['fileName']}
          />
          <div className='absolute z-40 bg-black/10 h-full w-full top-0 bottom-0 left-0 right-0 hidden group-hover:block duration-300 ease-in-out'></div>
          <div className='absolute z-50 hidden group-hover:flex items-center justify-center gap-2 duration-300 ease-in-out'>
            <a
              href={file['web']['filePath']}
              target='_blank'
              rel='noreferrer'
              className='btn btn-outline p-2'
              onClick={(e) => fileBoxClick_lightbox(e, file)}
            >
              <AiFillEye className='text-lg font-bold' />
            </a>
            <button className='btn btn-outline p-2'>
              <FaTrash className='text-lg font-bold' onClick={(e) => fileBoxClick_handler(e, file)} />
            </button>
          </div>
        </div>
      ) : (
        <div
          className='relative flex items-center justify-center flex-1 gap-2 px-2 py-1 shadow-sm rounded bg-secondary-200/60 border-[0.1px] border-secondary-200 cursor-pointer max-w-fit duration-300 ease-in-out group h-[100px]'
          ref={fileBoxRef}
        >
          <AiFillFile /> {getFileNameWithoutExtension(file['web']['fileName'])}
          <div className='absolute z-40 bg-black/10 h-full w-full top-0 bottom-0 left-0 right-0 hidden group-hover:block duration-300 ease-in-out'></div>
          <div className='absolute z-50 hidden group-hover:flex items-center justify-center gap-2 duration-300 ease-in-out'>
            <a
              href={file['web']['filePath']}
              target='_blank'
              rel='noreferrer'
              className='btn btn-outline p-2'
              onClick={(e) => fileBoxClick_lightbox(e, file)}
            >
              <AiFillEye className='text-lg font-bold' />
            </a>
            <button className='btn btn-outline p-2'>
              <FaTrash className='text-lg font-bold' onClick={(e) => fileBoxClick_handler(e, file)} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FileBox;
