import React from 'react';
import PortalCards from '../components/PortalCards';

const PortalPage = () => {
  return (
    <div className='flex flex-col gap-4'>
      <h1 className='title'>Selectare departament</h1>
      <PortalCards />
    </div>
  );
};

export default PortalPage;
