import { useNavigate, useParams } from 'react-router-dom';
import { useGetSettingsQuery } from '../../api/settingsApi';
import { Link } from 'react-router-dom';

const PaymentResultPage = () => {
  const { data: settings, isLoading: isSettingsLoading } = useGetSettingsQuery();
  const navigate = useNavigate();
  const params = useParams();
  let message = '';

  const departmentId = params?.departmentId;

  if (params?.status === 'success') {
    message = 'Plata dvs. a fost efectuata cu success!';
  } else if (params?.status === 'fail') {
    message = 'Plata dvs. nu a fost efectuata!';
  } else if (params?.invalid === 'invalid') {
    message = 'Plata dvs. nu fost efectuata!';
  }

  return (
    <div
      className='w-screen h-screen flex flex-col items-center justify-center gap-20 fade-in overflow-hidden bg-cover bg-no-repeat bg-center'
      style={{ backgroundImage: `url(${settings?.settings_splash_screen_img})` }}
    >
      <div className='text-center flex flex-col items-center justify-center gap-20 mt-[10%] slide-up'>
        <img src={settings?.settings_hall_logo} alt='Stema Primarie' width='100' height='100' />
        <div className='flex flex-col gap-4'>
          <h1 className='text-white uppercase text-5xl md:text-7xl font-bold'>{settings?.settings_hall_name}</h1>
          <h3 className='text-white uppercase text-xl md:text-3xl font-bold'>Serviciu Taxe si Impozite</h3>
        </div>
      </div>
      {message.length ? <p className='text-5xl font-bold text-white'>{message}</p> : ''}
      <Link to={`/taxes/${departmentId}`} className='text-lg uppercase text-primary-500 font-medium tracking-widest'>
        Inpoi la taxe
      </Link>
    </div>
  );
};

export default PaymentResultPage;
