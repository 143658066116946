const DebitItem = ({ debits }) => {
  return (
    <div className='flex flex-col gap-2'>
      {debits.map((debit) => (
        <div
          key={debit.debiton_id}
          className='bg-white text-black text-sm border-[1px] border-secondary-200 rounded-sm px-2 py-1 flex justify-between items-center gap-2'
        >
          <div>
            Nr. <span className='font-medium'>{debit.debiton_rolnr}</span>, Cap.{' '}
            <span className='font-medium'>{debit.debiton_capitol}</span>, Scap.{' '}
            <span className='font-medium'>{debit.debiton_scap}</span>, Den.{' '}
            <span className='font-medium'>{debit.debiton_dendeb}</span>, Titular{' '}
            <span className='font-medium'>{debit.debiton_numpre}</span>
          </div>
          <div className='flex gap-8 items-center'>
            <span className='font-bold'>{debit.debiton_wdeplata} LEI</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DebitItem;
