const webincarolStatus = {
  INITIATED: 0,
  PROCESSING: 1,
  SUCCESS: 2,
  FAILED: 3,
  INVALID: 4
};

export const webincarolStatusRev = {
  0: 'INITIATED',
  1: 'PROCESSING',
  2: 'SUCCESS',
  3: 'FAILED',
  4: 'INVALID'
};

export default webincarolStatus;
