import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

const eventsLogsApi = createApi({
  reducerPath: 'eventsLogsApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: window.API_URL
  }),
  tags: ['EventsLogs'],
  endpoints: (builder) => ({
    getEventsLogs: builder.query({
      query: () => `/eventsLogs.php`,
      providesTags: ['EventsLogs']
    })
  })
});

export const { useGetEventsLogsQuery } = eventsLogsApi;

export default eventsLogsApi;
