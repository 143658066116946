import React, { useEffect, useRef } from 'react';
import { AiOutlineClose, AiOutlineDownload } from 'react-icons/ai';
import { downloadFile, isImageFile } from '../utils/fn';

const Lightbox = ({ file: { fileExtension, fileName, filePath }, setShowLightbox }) => {
  const fileRefContainer = useRef(null);
  const fileRef = useRef(null);

  useEffect(() => {
    const file = fileRefContainer?.current;

    file?.addEventListener('click', fileClickHandler);

    function fileClickHandler(e) {
      e.preventDefault();

      if (e.target.contains(fileRef?.current)) {
        setShowLightbox(false);
      }
    }

    window.addEventListener('keydown', keyPressHandler);

    function keyPressHandler(e) {
      if (e.key === 'Escape') {
        setShowLightbox(false);
      }
    }

    return () => {
      file?.removeEventListener('click', fileClickHandler);
      window.removeEventListener('click', keyPressHandler);
    };
  }, [setShowLightbox]);

  function download(e) {
    downloadFile(fileName, filePath);
    setShowLightbox(false);
  }

  return (
    <div
      className='fixed z-[9999] bg-black/75 top-0 bottom-0 left-0 right-0  h-screen w-screen overflow-hidden flex items-center justify-center duration-200'
      ref={fileRefContainer}
    >
      <button
        className='absolute right-[0] top-[0] text-white hover:text-gray-300 duration-200 ease-in-out text-5xl mt-5 mr-10 md:mt-10 md:mr-20'
        onClick={(e) => setShowLightbox(false)}
      >
        <AiOutlineClose />
      </button>
      <a
        href={filePath}
        target='_blank'
        rel='noreferrer'
        className='absolute left-[0] top-[0] text-white hover:text-gray-300 duration-200 ease-in-out text-5xl mt-5 ml-10 md:mt-10 md:ml-20'
        onClick={download}
      >
        <AiOutlineDownload />
      </a>
      <div
        className='max-h-[75vh] max-w-[90vw] md:max-w-[75vw] w-full shadow-2xl flex items-center justify-center'
        ref={fileRef}
      >
        {isImageFile(fileName) ? (
          <img src={filePath} alt={fileName} />
        ) : (
          <embed src={filePath} type='application/pdf' className='h-[75vh] w-full' />
        )}
      </div>
    </div>
  );
};

export default Lightbox;
