const consts = {
  LOCAL_STORAGE_KEY_SPLASH_SCREEN_MESSAGE: 'LOCAL_STORAGE_KEY_SPLASH_SCREEN_MESSAGE',
  // API_URL: '../server/api',
  API_URL: 'http://localhost/impozite-taxe/server/api',
  // API_URL: 'https://infoghidsandbox.ro/server/api',
  RECAPTCHA_SITE_KEY: '6Ld2h14kAAAAAH9Py5nID5E-bNAG9lA3vafHsUUP',
  COOKIE_KEY_JWT: 'COOKIE_KEY_JWT',
  LOCAL_STORAGE_KEY_PAGE_MESSAGE: 'LOCAL_STORAGE_KEY_PAGE_MESSAGE',
  FILES_INPUT_TEXT_INFO: 'Extensii acceptate: JPG/JPEG/PDF; Dimensiune maxima fisier: 10MB',
  SUBTITLE_TEXT: 'REGISTRATURA'
};

export default consts;
