import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

const ticketsApi = createApi({
  reducerPath: 'ticketsApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: window.API_URL + '/tickets'
  }),
  tags: ['Tickets'],
  endpoints: (builder) => ({
    getTickets: builder.query({
      query: () => `/tickets.php?all=1`,
      providesTags: ['Tickets']
    }),
    getTicketsByUserId: builder.query({
      query: (id) => `/tickets.php?ticket_user_id=${id}&all=1`,
      providesTags: ['Tickets']
    }),
    getTicketsByUserIdAndDepartmentId: builder.query({
      query: ({ userId, departmentId }) =>
        `/tickets.php?ticket_user_id=${userId}&ticket_department_id=${departmentId}&all=1`,
      providesTags: ['Tickets']
    }),
    getTicket: builder.query({
      query: (id) => `/tickets.php?ticket_id=${id}`,
      providesTags: ['Tickets']
    }),
    addTicket: builder.mutation({
      query: (data) => ({
        url: '/tickets.php',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Tickets']
    }),
    addTicketContent: builder.mutation({
      query: (data) => ({
        url: '/ticketContent.php',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Tickets']
    }),
    updateTicket: builder.mutation({
      query: ({ data, id }) => ({
        url: `/tickets.php?ticket_id=${id}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Tickets']
    }),
    removeTicket: builder.mutation({
      query: (id) => ({
        url: `/tickets.php?ticket_id=${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Tickets']
    })
  })
});

export const {
  useGetTicketsQuery,
  useGetTicketQuery,
  useGetTicketsByUserIdQuery,
  useAddTicketMutation,
  useUpdateTicketMutation,
  useAddTicketContentMutation,
  useGetTicketsByUserIdAndDepartmentIdQuery,
  useRemoveTicketMutation
} = ticketsApi;

export default ticketsApi;
