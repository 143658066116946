import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Spinner from '../components/Spinner';
import Navbar from './Navbar';
import Footer from './Footer';
import { useGetSettingsQuery } from '../api/settingsApi';

const Layout = () => {
  const { data: settings, isLoading: isSettingsLoading } = useGetSettingsQuery();
  const [isLoading, setIsLoading] = useState(true);
  const [layoutSettings, setLayoutSettings] = useState({});

  useEffect(() => {
    if (!isSettingsLoading && settings) {
      setLayoutSettings(settings);
      setIsLoading(isSettingsLoading);
    }
  }, [settings, isSettingsLoading]);

  const navBarProps = {
    logoUrl: layoutSettings?.settings_hall_logo,
    hallName: layoutSettings?.settings_hall_name
  };

  const footerProps = {
    ...navBarProps,
    hallAddress: layoutSettings?.settings_hall_address,
    hallPhone: layoutSettings?.settings_hall_phone,
    hallEmail: layoutSettings?.settings_hall_email,
    hallWebsite: layoutSettings?.settings_hall_website
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Navbar {...navBarProps} />
      <div
        className={`bg-cover bg-no-repeat bg-center`}
        style={{ backgroundImage: `url(${settings?.settings_general_app_img})` }}
      >
        <main className={`container h-full minHeightCalc mx-auto pb-10`}>
          <Outlet />
        </main>
      </div>
      <Footer {...footerProps} />
    </>
  );
};

export default Layout;
