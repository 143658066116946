import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from './layout/Layout';

import RegisterPage from './pages/auth/RegisterPage';
import LoginPage from './pages/auth/LoginPage';
import ActivationPage from './pages/auth/ActivationPage';
import VerificationPage from './pages/auth/VerificationPage';
import PasswordResetPage from './pages/auth/PasswordResetPage';

import PrivateRoute from './components/routes/PrivateRoute';
import PublicRoute from './components/routes/PublicRoute';
import AdminRoute from './components/routes/AdminRoute';
import PermissionRoute from './components/routes/PermissionRoute';

import ContactPage from './pages/ContactPage';
import SplashScreen from './components/SplashScreen';

import ProfilePage from './pages/taxes/ProfilePage';
import DashboardPage from './pages/taxes/DashboardPage';
import UsersPage from './pages/taxes/UsersPage';
import SettingsPage from './pages/taxes/SettingsPage';
import LogsPage from './pages/taxes/LogsPage';
import EventsLogsPage from './pages/taxes/EventsLogsPage';
import TaxesPage from './pages/taxes/TaxesPage';
import PaymentHistoryPage from './pages/taxes/PaymentHistoryPage';
import PaymentResultPage from './pages/taxes/PaymentResultPage';

import PortalPage from './pages/PortalPage';

import AboutPage from './pages/AboutPage';
import DashboardTicketsPage from './pages/tickets/DashboardTicketsPage';
import TicketsPage from './pages/tickets/TicketsPage';
import DepartmentsPage from './pages/DepartmentsPage';
import TypedDocumentTypesPage from './pages/tickets/TypedDocumentTypesPage';
import RequestTypesPage from './pages/tickets/RequestTypesPage';
import TicketPage from './pages/tickets/TicketPage';
import SuperAdminRoute from './components/routes/SuperAdminRoute';

function App() {
  return (
    <>
      <Routes>
        {/* non restricted routes wihout layout */}
        <Route path='' element={<SplashScreen />} />

        {/* private routes without layout */}
        <Route element={<PrivateRoute />}>
          <Route path='paymentresult/:status/:departmentId' element={<PaymentResultPage />} />
        </Route>

        <Route path='*' element={<Layout />}>
          {/* non restricted routes */}
          <Route path='contact' element={<ContactPage />} />
          <Route path='contact/:departmentId' element={<ContactPage />} />

          {/* public routes */}
          {/* auth */}
          <Route element={<PublicRoute />}>
            <Route path='activation/:activationCode?' element={<ActivationPage />} />
            <Route path='login' element={<LoginPage />} />
            <Route path='verification/:userId' element={<VerificationPage />} />
            <Route path='register' element={<RegisterPage />} />
            <Route path='reset' element={<PasswordResetPage />} />
          </Route>

          {/* private routes */}
          <Route element={<PrivateRoute />}>
            <Route path='portal' element={<PortalPage />} />
            <Route path='departments' element={<DepartmentsPage />} />
            <Route path='about' element={<AboutPage />} />

            <Route path='tickets'>
              <Route path=':departmentId/:ticketId' element={<TicketPage />} />
              <Route path=':departmentId' element={<TicketsPage />} />
              <Route path='dashboard/:departmentId' element={<DashboardTicketsPage />} />
              {/* <Route path='settings/:departmentId' element={<TicketsSettingsPage />} /> */}
              <Route path='typeddocumenttypes/:departmentId' element={<TypedDocumentTypesPage />} />
              <Route path='requesttypes/:departmentId' element={<RequestTypesPage />} />
            </Route>

            <Route path='dashboard/:departmentId' element={<DashboardPage />} />
            <Route path='profile' element={<ProfilePage />} />
            <Route path='paymenthistory/:departmentId' element={<PaymentHistoryPage />} />

            {/* permission routes */}
            <Route element={<PermissionRoute />}>
              <Route path='taxes/:departmentId' element={<TaxesPage />} />
            </Route>

            {/* super admin routes */}
            <Route element={<SuperAdminRoute />}>
              <Route path='settings' element={<SettingsPage />} />
            </Route>

            {/* admin routes */}
            <Route element={<AdminRoute />}>
              <Route path='users' element={<UsersPage />} />
              <Route path='logs' element={<LogsPage />} />
              <Route path='eventslogs/:departmentId' element={<EventsLogsPage />} />
            </Route>
          </Route>
          <Route path='*' element={<Navigate to='/' />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
