import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: window.API_URL
  }),
  tags: ['Settings'],
  endpoints: (builder) => ({
    getSettings: builder.query({
      query: () => '/settings.php',
      providesTags: ['Settings']
    }),
    updateSettings: builder.mutation({
      query: (data) => ({
        url: '/settings.php',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Settings']
    })
  })
});

export const { useGetSettingsQuery, useUpdateSettingsMutation } = settingsApi;

export default settingsApi;
