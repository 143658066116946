import useCurrentDepartment from '../../hooks/useCurrentDepartment';
import Switch from '../../components/Switch';
import { useUpdateDepartmentSettingsMutation } from '../../api/departmentsApi';
import { toast } from 'react-toastify';

const TicketsSettingsPage = ({ departmentId }) => {
  const { currentDepartment, isLoading: isDepartmentLoading } = useCurrentDepartment(departmentId);

  const [updateDepartmentSettings] = useUpdateDepartmentSettingsMutation();

  async function changeSettings_handler(key, value) {
    try {
      const settings = currentDepartment.departmentSettings;

      settings[key] = value;

      const res = await updateDepartmentSettings({ data: settings, id: settings.department_settings_id }).unwrap();

      const { success = false, message = '' } = res;

      !success && message && toast.error(message);
    } catch (error) {
      console.error(error);
    }
  }

  return !isDepartmentLoading && currentDepartment ? (
    <div className='flex flex-col gap-4'>
      <h1 className='subTitle'>Setari departament</h1>
      <ul className='flex flex-col gap-4'>
        <li className='border-b-2 border-secondary-100 py-4 items-center'>
          <Switch
            title={'Departament activ'}
            checked={!!+currentDepartment.departmentSettings.department_settings_is_department_active}
            name='department_settings_is_department_active'
            fn={changeSettings_handler}
          />
        </li>
        <li
          className={
            currentDepartment.department_type.trim().toUpperCase() === 'TAXES'
              ? `border-b-2 border-secondary-100 py-4`
              : ''
          }
        >
          <Switch
            title={'Contul utlizatorului trebuie activat'}
            checked={!!+currentDepartment.departmentSettings.department_settings_user_needs_activation}
            name='department_settings_user_needs_activation'
            fn={changeSettings_handler}
          />
        </li>
        {currentDepartment.department_type.trim().toUpperCase() === 'TAXES' && (
          <li>
            <Switch
              title={'Zona de solicitari este activa'}
              checked={!!+currentDepartment.departmentSettings.department_settings_is_tickets_active}
              name='department_settings_is_tickets_active'
              fn={changeSettings_handler}
            />
          </li>
        )}
      </ul>
    </div>
  ) : (
    ''
  );
};

export default TicketsSettingsPage;
