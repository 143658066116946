import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RO } from 'material-react-table/locales/ro';
import Modal from '@mui/material/Modal';
import { useMemo, useState } from 'react';
import useCurrentDepartment from '../../hooks/useCurrentDepartment';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRef } from 'react';
import { FaTrash } from 'react-icons/fa';
import { AiFillEdit, AiOutlineClose, AiOutlineQuestionCircle } from 'react-icons/ai';
import {
  useGetRequestTypesQuery,
  useGetDepartmentRequestTypesQuery,
  useAddRequestTypeMutation,
  useUpdateRequestTypeMutation,
  useRemoveRequestTypeMutation
} from '../../api/typesApi';
import { formatDate } from '../../utils/fn';
import { useGetRequestCategoriesQuery } from '../../api/categoriesApi';
import Spinner from '../../components/Spinner';

const RequestTypesPage = ({ departmentId }) => {
  const { currentDepartment, isLoading: isDepartmentLoading } = useCurrentDepartment(departmentId);

  const { data: requestTypes = [], isLoading: isRequestTypesLoading } = useGetDepartmentRequestTypesQuery(departmentId);

  const [addRequestType] = useAddRequestTypeMutation();

  const [updateRequestType] = useUpdateRequestTypeMutation();

  const [removeRequestType] = useRemoveRequestTypeMutation();

  const [isLoading, setIsLoading] = useState(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'request_type_name',
        header: 'Denumire',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>,
        size: 200
      },
      {
        accessorKey: 'request_type_description',
        header: 'Descriere',
        size: 300
      },
      {
        accessorKey: 'request_type_deadline',
        accessorFn: (row) => row.request_type_deadline_days,
        header: 'Deadline',
        size: 100
      }
    ],
    []
  );

  const formRef = useRef(null);

  const [operatedRow, setOperatedRow] = useState(null);

  const [operatedRowId, setOperatedRowId] = useState(0);

  const [isAddUpdateModalOpen, setIsAddUpdateModalOpen] = useState(false);

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const schema = yup.object().shape({
    request_type_name: yup.string().required('Camp obligatoriu'),
    request_type_description: yup.string().required('Camp obligatoriu'),
    request_type_deadline_days: yup
      .number()
      .min(0, 'Numarul de zile nu poate fi mai mic decat 0')
      .required('Camp obligatoriu')
      .typeError('Numar invalid')
  });

  const {
    register,
    trigger,
    resetField,
    setValue,
    setError,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) });

  function resetModalFields() {
    resetField('request_type_name');
    resetField('request_type_description');
    resetField('request_type_deadline');
  }

  function closeModals() {
    setIsRemoveModalOpen(false);
    setIsAddUpdateModalOpen(false);
    setOperatedRow(null);
    resetModalFields();
  }

  async function removeRequestType_handler(data) {
    setIsLoading(true);

    try {
      const res = await removeRequestType(data.request_type_id).unwrap();
      const { success = false, message = '' } = res;
      if (success) {
        message && toast.success(message);
      } else {
        message && toast.error(message);
      }
      closeModals();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function edit_handler(data) {
    setOperatedRowId(data.request_type_id);
    setOperatedRow(data);
    setIsAddUpdateModalOpen(true);
    setValue('request_type_name', data.request_type_name);
    setValue('request_type_description', data.request_type_description);
    setValue('request_type_category', data.request_type_category);
    setValue('request_type_deadline_days', data.request_type_deadline_days);
  }

  async function addOrUpdate_handler(data) {
    try {
      let res;

      const isValid = await trigger();

      if (!isValid) {
        return;
      }

      const formData = Object.fromEntries(new FormData(formRef.current).entries());

      setIsLoading(true);

      if (operatedRow) {
        res = await updateRequestType({ data: formData, id: data.request_type_id }).unwrap();
      } else {
        formData.request_type_department_id = departmentId;
        res = await addRequestType(formData).unwrap();
      }

      const { success = false, message = '', id = 0 } = res;

      if (success) {
        message && toast.success(message);
        closeModals();
      } else {
        message && toast.error(message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    currentDepartment && (
      <div className='flex flex-col gap-4'>
        {/* modal for removing the department */}
        <Modal open={isRemoveModalOpen} onClose={closeModals}>
          <div className='modalContainer md:max-w-[600px]'>
            <div className='modalHeader'>
              <h1 className='subTitle text-white'>Eliminare tip solicitare</h1>
              <button className='text-gray-100 hover:text-white text-xl' onClick={closeModals}>
                <AiOutlineClose />
              </button>
            </div>
            <div className='modalBody'>
              <p>
                Tipul de solicitare <strong className='uppercase'>{operatedRow?.request_type_name}</strong> va fi
                eliminat, sunteti sigur?
              </p>
            </div>
            <div className='modalFooter'>
              <button className='btn btn-primary' onClick={() => removeRequestType_handler(operatedRow)} disabled={isLoading}>
                {isLoading ? <Spinner/> : 'Da'}
              </button>
              <button className='btn btn-dark' onClick={closeModals}>
                Anulare
              </button>
            </div>
          </div>
        </Modal>
        {/* modal for adding */}
        <Modal
          open={isAddUpdateModalOpen}
          onClose={(e) => {
            closeModals();
          }}
        >
          <div className='modalContainer md:max-w-[600px]'>
            <div className='modalHeader'>
              <h1 className='subTitle text-white'>
                {operatedRow ? 'Modificare tip solicitare' : 'Adaugare tip solicitare'}
              </h1>
              <button
                className='text-gray-100 hover:text-white text-xl'
                onClick={(e) => {
                  closeModals();
                }}
              >
                <AiOutlineClose />
              </button>
            </div>
            <div className='modalBody'>
              <form ref={formRef} className='w-full flex flex-col'>
                <div className='formControl'>
                  <label className='formLabel'>Denumire:</label>
                  <input
                    type='text'
                    className={`formInput ${errors?.request_type_name && 'border-[0.1px] border-primary-500'}`}
                    {...register('request_type_name')}
                  />
                  <p className='inputErrorMessage'>{errors?.request_type_name?.message}</p>
                </div>
                <div className='formControl'>
                  <label className='formLabel flex items-center'>
                    Deadline zile:
                    <AiOutlineQuestionCircle
                      className='text-primary-500'
                      title='Numarul de zile care au trecut din momentul in care o solicitare a fost emisa si nu s-a primit niciun raspuns din partea unui operator al primariei, moment in care se va trimite un email de avertizare'
                    />
                  </label>
                  <input
                    type='number'
                    className={`formInput ${errors?.request_type_deadline_days && 'border-[0.1px] border-primary-500'}`}
                    min={0}
                    {...register('request_type_deadline_days')}
                  />
                  <p className='inputErrorMessage'>{errors?.request_type_deadline_days?.message}</p>
                </div>
                <div className='formControl'>
                  <label className='formLabel'>Descriere:</label>
                  <textarea
                    type='text'
                    className={`formInput ${errors?.request_type_description && 'border-[0.1px] border-primary-500'}`}
                    rows={5}
                    {...register('request_type_description')}
                  />
                  <p className='inputErrorMessage'>{errors?.request_type_description?.message}</p>
                </div>
              </form>
            </div>
            <div className='modalFooter'>
              <button
                onClick={(e) => addOrUpdate_handler(operatedRow)}
                type='submit'
                className='btn btn-primary self-start w-full xs:max-w-fit'
                disabled={isLoading}
              >
                {isLoading ? <Spinner/> : (operatedRow ? 'Modificare' : 'Adaugare')}
              </button>
              <button
                className='btn btn-dark'
                onClick={() => {
                  closeModals();
                }}
              >
                Anulare
              </button>
            </div>
          </div>
        </Modal>
        <h1 className='subTitle'>Tipuri solicitari</h1>
        <div className='w-full'>
          <MaterialReactTable
            columns={columns}
            data={requestTypes ?? []}
            getRowId={(row) => row.request_type_id}
            state={{ isRequestTypesLoading }}
            enableStickyHeader
            enableStickyFooter
            enableColumnResizing
            localization={MRT_Localization_RO}
            initialState={{
              showGlobalFilter: true,
              density: 'compact',
              columnPinning: { right: ['actions'] }
            }}
            // renderTopToolbarCustomActions={({ table }) => null}
            enablePinning
            // defaultColumn={{
            //   maxSize: 400,
            //   minSize: 80,
            //   size: 100 //default size is usually 180
            // }}
            enableDensityToggle={false}
            layoutMode='grid'
            enableRowActions
            displayColumnDefOptions={{
              'mrt-row-actions': {
                header: '', //change header text
                minSize: 30,
                maxSize: 300
              }
            }}
            renderRowActions={({ row: { original: data } }, index) => (
              <div className='flex gap-1'>
                <button className='btn btn-outline btn-xm hover:scale-[0.99] p-1' onClick={(e) => edit_handler(data)}>
                  <AiFillEdit />
                </button>
                <button
                  className='btn btn-outline btn-xm hover:scale-[0.99] p-1'
                  onClick={(e) => {
                    setOperatedRowId(data.request_type_id);
                    setOperatedRow(data);
                    setIsRemoveModalOpen(true);
                  }}
                >
                  <FaTrash />
                </button>
              </div>
            )}
            muiTableBodyRowProps={({ row: { original: data } }) => ({
              sx: {
                ...(data.request_type_id === operatedRowId && { background: '#fbd6d3' })
              },
              hover: false
            })}
            renderTopToolbarCustomActions={({ table }) => (
              <div>
                <button className='btn btn-dark' onClick={(e) => setIsAddUpdateModalOpen(true)}>
                  Adaugare
                </button>
              </div>
            )}
            positionActionsColumn='last'
            muiTableBodyCellProps={{
              sx: {
                whiteSpace: 'pre-line'
              }
            }}
            muiTableHeadCellProps={{
              sx: {
                // background: "#475569",
                color: 'black',
                fontWeight: 'bold'
              }
            }}
            muiTableContainerProps={{
              sx: {
                maxHeight: '500px'
              }
            }}
            muiTablePaperProps={{
              sx: {
                borderRadius: '0',
                border: '0.1px solid #e0e0e0',
                boxShadow: 'none'
              }
            }}
            muiTableProps={{
              sx: {
                boxShadow: 'none'
              }
            }}
            muiTableHeadRowProps={{
              sx: {
                boxShadow: 'none'
              }
            }}
          />
        </div>
      </div>
    )
  );
};

export default RequestTypesPage;
