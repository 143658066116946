import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import consts from '../consts/consts';
import Spinner from './Spinner';

import { useGetConnectedUserQuery } from '../api/authApi';
import { useGetSettingsQuery } from '../api/settingsApi';

const SplashScreen = () => {
  const [cookies] = useCookies([consts.COOKIE_KEY_JWT]);
  const { data: user, isUserLoading } = useGetConnectedUserQuery(cookies[consts.COOKIE_KEY_JWT]);
  const { data: settings, isLoading: isSettingsLoading } = useGetSettingsQuery();

  let message = '';

  if (localStorage.getItem(consts.LOCAL_STORAGE_KEY_SPLASH_SCREEN_MESSAGE)) {
    message = localStorage.getItem(consts.LOCAL_STORAGE_KEY_SPLASH_SCREEN_MESSAGE);
    localStorage.removeItem(consts.LOCAL_STORAGE_KEY_SPLASH_SCREEN_MESSAGE);
  }

  if (isUserLoading || isSettingsLoading) {
    return (
      <div className='w-screen h-screen flex flex-col items-center justify-center'>
        <Spinner size={60} />
      </div>
    );
  }

  return (
    <div
      className='w-screen h-screen flex flex-col items-center justify-center gap-20 fade-in overflow-hidden bg-cover bg-no-repeat bg-center'
      style={{ backgroundImage: `url(${settings?.settings_splash_screen_img})` }}
    >
      <div className='text-center flex flex-col items-center justify-center gap-20 mt-[10%] slide-up'>
        {/* <img src={settings?.settings_hall_logo} alt='Stema Primarie' width='100' height='100' /> */}
        <img src={settings?.settings_hall_logo} alt='Stema Primarie' width='250' height='250' className='rounded-xl' />
        <div className='flex flex-col gap-4'>
          <h1 className='text-white uppercase text-5xl md:text-7xl font-bold'>{settings?.settings_hall_name}</h1>
          <h3 className='text-white uppercase text-xl md:text-3xl font-bold'>{consts['SUBTITLE_TEXT']}</h3>
        </div>
      </div>
      {message.length ? <p>{message}</p> : ''}
      {!isUserLoading ? (
        !user ? (
          <>
            <div className='flex flex-wrap gap-8 items-center justify-center'>
              <Link to='/login' className='btn btn-primary text-xl px-10 py-3 uppercase'>
                Conectare
              </Link>
              <Link to='/register' className='btn btn-outline border-[2px] text-xl px-10 py-3 uppercase'>
                Inscriere
              </Link>
            </div>
            <Link to='/contact' className='text-lg uppercase text-primary-500 font-medium tracking-widest'>
              Contact
            </Link>
          </>
        ) : (
          <Link to='/portal' className='btn btn-primary text-xl px-10 py-3 uppercase'>
            Acasa
          </Link>
        )
      ) : (
        ''
      )}
    </div>
  );
};

export default SplashScreen;
