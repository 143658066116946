import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RO } from 'material-react-table/locales/ro';
import { useMemo } from 'react';
import { formatDate } from '../../utils/fn';
import { useGetEventsLogsQuery } from '../../api/eventsLogsApi';
import useCurrentDepartment from '../../hooks/useCurrentDepartment';

const EventsLogsPage = () => {
  const { data: eventsLogs, isLoading: isEventsLogsLoading } = useGetEventsLogsQuery();
  const { currentDepartment, isLoading: isCurrentDepartmentLoading } = useCurrentDepartment();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'event_log_debit_key',
        header: 'Id',
        size: 100
      },
      {
        accessorKey: 'event_log_operation',
        header: 'Operatie',
        size: 60
      },
      {
        accessorKey: 'event_log_description',
        header: 'Descriere',
        size: 300
      },
      {
        accessorKey: 'event_log_created_at',
        accessorFn: (row) => formatDate(row.event_log_created_at, true),
        header: 'Data',
        size: 80
      }
    ],
    []
  );

  return (
    <div className='flex flex-col gap-4'>
      <h1 className='title'>{currentDepartment?.department_name} / Jurnal evenimente</h1>
      <div className='w-full'>
        <MaterialReactTable
          columns={columns}
          data={eventsLogs || []}
          getRowId={(row) => row.log_id}
          state={{ isEventsLogsLoading }}
          enableStickyHeader
          enableStickyFooter
          enableColumnResizing
          localization={MRT_Localization_RO}
          initialState={{
            showGlobalFilter: true,
            density: 'compact',
            columnPinning: { right: ['actions'] }
          }}
          muiTableBodyCellProps={{
            sx: {
              wordWrap: 'break-word',
              wordBreak: 'break-all',
              whiteSpace: 'pre-line'
            }
          }}
          enablePinning
          // defaultColumn={{
          //   maxSize: 400,
          //   minSize: 80,
          //   size: 100 //default size is usually 180
          // }}
          enableDensityToggle={false}
          layoutMode='grid'
          // enableRowActions
          // displayColumnDefOptions={{
          //   'mrt-row-actions': {
          //     header: '', //change header text
          //     minSize: 142,
          //     maxSize: 300
          //   }
          // }}
          positionActionsColumn='last'
          muiTableHeadCellProps={{
            sx: {
              // background: "#475569",
              color: 'black',
              fontWeight: 'bold'
            }
          }}
          muiTableContainerProps={{
            sx: {
              maxHeight: '500px'
            }
          }}
          muiTablePaperProps={{
            sx: {
              borderRadius: '0',
              border: '0.1px solid #e0e0e0',
              boxShadow: 'none'
            }
          }}
          muiTableProps={{
            sx: {
              boxShadow: 'none'
            }
          }}
          muiTableHeadRowProps={{
            sx: {
              boxShadow: 'none'
            }
          }}
        />
      </div>
    </div>
  );
};

export default EventsLogsPage;
