import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

const departmentsApi = createApi({
  reducerPath: 'departmentsApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: window.API_URL
  }),
  tags: ['Departments'],
  endpoints: (builder) => ({
    getDepartment: builder.query({
      query: (id) => `/departments.php?department_id=${id}`,
      providesTags: ['Departments']
    }),
    getDepartments: builder.query({
      query: () => `/departments.php`,
      providesTags: ['Departments']
    }),
    getDepartmentSettings: builder.query({
      query: (departmentId) => `/departmentSettings.php?department_settings_department_id=${departmentId}`,
      providesTags: ['Departments']
    }),
    updateDepartmentSettings: builder.mutation({
      query: ({ data, id }) => ({
        url: `/departmentSettings.php?department_settings_id=${id}`,
        method: 'POST',
        body: JSON.stringify(data)
      }),
      invalidatesTags: ['Departments']
    }),
    addDepartment: builder.mutation({
      query: (data) => ({
        url: `/departments.php`,
        method: 'POST',
        body: JSON.stringify(data)
      }),
      invalidatesTags: ['Departments']
    }),
    updateDepartment: builder.mutation({
      query: ({ data, id }) => ({
        url: `/departments.php?department_id=${id}`,
        method: 'POST',
        body: JSON.stringify(data)
      }),
      invalidatesTags: ['Departments']
    }),
    removeDepartment: builder.mutation({
      query: (id) => ({
        url: `/departments.php?department_id=${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Departments']
    })
  })
});

export const {
  useGetDepartmentsQuery,
  useGetDepartmentSettingsQuery,
  useAddDepartmentMutation,
  useUpdateDepartmentMutation,
  useRemoveDepartmentMutation,
  useUpdateDepartmentSettingsMutation
} = departmentsApi;

export default departmentsApi;
