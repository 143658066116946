import { useRef, useState } from 'react';

const Switch = ({ title, checked, name = '', fn = null, data = {} }) => {
  const switchInputRef = useRef(null);
  const [isSwitchOn, setIsSwitchOn] = useState(checked);

  function switch_handler() {
    setIsSwitchOn((prev) => !prev);

    if (data && data.id) {
      fn(data.id, !isSwitchOn);
    } else {
      fn(name, !isSwitchOn);
    }
  }

  return (
    <div className='flex justify-between items-center'>
      <h3 className='text-lg font-medium'>{title}</h3>
      <div className='relative cursor-pointer' onClick={(e) => switch_handler()}>
        <div className='bg-secondary-200 rounded-lg w-14 h-[1.7rem] shadow px-[3px] flex items-center'>
          <div
            className={`w-6 h-6 ${isSwitchOn ? 'bg-primary-500' : 'bg-white'} rounded-full relative top-0 ${
              isSwitchOn ? 'left-0' : 'left-[calc(100%-1.5rem)]'
            } shadow-md ease-in-out duration-300`}
          ></div>
        </div>
      </div>
      <input type='checkbox' hidden ref={switchInputRef} checked={isSwitchOn} readOnly />
    </div>
  );
};

export default Switch;
