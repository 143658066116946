import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

const webincarolApi = createApi({
  reducerPath: 'webincarolApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: window.API_URL
  }),
  tags: ['Webincarol'],
  endpoints: (builder) => ({
    getWebincarol: builder.query({
      query: (cuiCnp = '') => `/taxes/webincarol.php?cuiCnp=${cuiCnp}`,
      providesTags: ['Webincarol']
    })
  })
});

export const { useGetWebincarolQuery } = webincarolApi;

export default webincarolApi;
