import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

const typesApi = createApi({
  reducerPath: 'typesApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: window.API_URL + '/tickets'
  }),
  tags: ['TypedDocumentTypes', 'RequestTypes', 'AttachmentTypes'],
  endpoints: (builder) => ({
    getTypedDocumentTypesByDepartmentAndRequestType: builder.query({
      query: ({ departmentId, requestTypeId }) =>
        `/typedDocumentTypes.php?typed_document_type_request_type_id=${requestTypeId}&typed_document_type_department_id=${departmentId}`,
      providesTags: ['TypedDocumentTypes']
    }),
    getTypedDocumentTypesByRequestType: builder.query({
      query: (requestTypeId) => `/typedDocumentTypes.php?typed_document_request_type_id=${requestTypeId}`,
      providesTags: ['TypedDocumentTypes']
    }),
    getDepartmentTypedDocumentTypes: builder.query({
      query: (departmentId) => `/typedDocumentTypes.php?typed_document_type_department_id=${departmentId}`,
      providesTags: ['TypedDocumentTypes']
    }),
    getTypedDocumentTypes: builder.query({
      query: () => '/typedDocumentTypes.php',
      providesTags: ['TypedDocumentTypes']
    }),
    getTypedDocumentType: builder.query({
      query: (id) => `/typedDocumentTypes.php?typed_document_type_id=${id}`,
      providesTags: ['TypedDocumentTypes']
    }),
    addTypedDocumentType: builder.mutation({
      query: (data) => ({
        url: `/typedDocumentTypes.php`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['TypedDocumentTypes']
    }),
    updateTypedDocumentType: builder.mutation({
      query: ({ data, id }) => ({
        url: `/typedDocumentTypes.php?typed_document_type_id=${id}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['TypedDocumentTypes']
    }),
    removeTypedDocumentType: builder.mutation({
      query: (id) => ({
        url: `/typedDocumentTypes.php?typed_document_type_id=${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['TypedDocumentTypes']
    }),
    removeTypedDocumentTypeFile: builder.mutation({
      query: (serverFilePath) => ({
        url: `/typedDocumentTypes.php?server_file_path=${serverFilePath}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['TypedDocumentTypes']
    }),
    getDepartmentRequestTypes: builder.query({
      query: (departmentId) => `/requestTypes.php?request_type_department_id=${departmentId}`,
      providesTags: ['RequestTypes']
    }),
    getRequestTypes: builder.query({
      query: () => '/requestTypes.php',
      providesTags: ['RequestTypes']
    }),
    getRequestTypesByDepartment: builder.query({
      query: (id) => `/requestTypes.php?request_type_department_id=${id}`,
      providesTags: ['RequestTypes']
    }),
    getRequestType: builder.query({
      query: (id) => `/requestTypes.php?request_type_id=${id}`,
      providesTags: ['RequestTypes']
    }),
    addRequestType: builder.mutation({
      query: (data) => ({
        url: `/requestTypes.php`,
        method: 'POST',
        body: JSON.stringify(data)
      }),
      invalidatesTags: ['RequestTypes']
    }),
    updateRequestType: builder.mutation({
      query: ({ data, id }) => ({
        url: `/requestTypes.php?request_type_id=${id}`,
        method: 'POST',
        body: JSON.stringify(data)
      }),
      invalidatesTags: ['RequestTypes']
    }),
    removeRequestType: builder.mutation({
      query: (id) => ({
        url: `/requestTypes.php?request_type_id=${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['RequestTypes']
    })
  })
});

export const {
  useGetRequestTypesByDepartmentQuery,
  useGetTypedDocumentTypesQuery,
  useGetTypedDocumentTypeQuery,
  useAddTypedDocumentTypeMutation,
  useUpdateTypedDocumentTypeMutation,
  useRemoveTypedDocumentTypeMutation,
  useGetRequestTypesQuery,
  useGetRequestTypeQuery,
  useUpdateRequestTypeMutation,
  useAddRequestTypeMutation,
  useRemoveRequestTypeMutation,
  useGetDepartmentRequestTypesQuery,
  useGetDepartmentTypedDocumentTypesQuery,
  useGetTypedDocumentTypesByRequestTypeQuery,
  useGetTypedDocumentTypesByDepartmentAndRequestTypeQuery,
  useRemoveTypedDocumentTypeFileMutation
} = typesApi;

export default typesApi;
