import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: window.API_URL
  }),
  tags: ['TypedDocumentCategories', 'RequestCategories'],
  endpoints: (builder) => ({
    getTypedDocumentCategory: builder.query({
      query: (typedDocumentId) => `typedDocumentCategories.php?typed_document_id=${typedDocumentId}`,
      providesTags: ['TypedDocumentCategories']
    }),
    getTypedDocumentCategories: builder.query({
      query: (departmentId) => `typedDocumentCategories.php?departmentId=${departmentId}`,
      providesTags: ['TypedDocumentCategories']
    }),
    getRequestCategory: builder.query({
      query: (requestId) => `requestCategories.php?request_category_id=${requestId}`,
      providesTags: ['RequestCategories']
    }),
    getRequestCategories: builder.query({
      query: (departmentId) => `requestCategories.php?departmentId=${departmentId}`,
      providesTags: ['RequestCategories']
    })
  })
});

export const {
  useGetTypedDocumentCategoryQuery,
  useGetTypedDocumentCategoriesQuery,
  useGetRequestCategoryQuery,
  useGetRequestCategoriesQuery
} = categoriesApi;

export default categoriesApi;
